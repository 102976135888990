import dynamic from "next/dynamic";
import Link from "next/link";
import SearchBox from "./searchBox";
import { useRouter } from "next/router";
import ShoppingCart from "./shoppingCart";
import Country from "./country";
import { useEffect, useState } from "react";
import { getCookie } from "@utils/Cookies";
import { COOKIE_AREA_KEY, COOKIE_COUNTRY_KEY, COOKIE_IS_OLD_USER, COOKIE_LOCALE_KEY, NEXT_LOCALE_KEY } from "@utils/constants";
import { useAppContext } from "@context/app";
import Image from "next/image";
import PathIcon from "@svg/PathIcon";
import { commonApi, userApi } from "gate";
import MobileHeader from "./mobileHeader";
import { IAreaDetailInput, IMenu, IStaticMenu } from "@types";
import { linkGenerator } from "@utils/linkGenerator";
import useEvents from "@hooks/useEvents";
import { toast } from "react-toastify";
import { getNotifyConfig } from "@utils/helpers";
import LocationPermission from "./permission";
import { useUI } from "@context/ui-managed-context";

const ProfileDropdown = dynamic(() => import("./profile-dropdown"), {
  ssr: false,
});
const ChangeCountry = dynamic(() => import("./changeCountry"), {
  ssr: false,
});
const ConfirmLocation = dynamic(() => import("./confirmLocation"), {
  ssr: false,
});

interface Props {
  menu: IMenu | IStaticMenu;
  isBanner: boolean;
  setIsOpenConfirm: (data: boolean) => void
}

const Header: React.FC<Props> = ({ menu, isBanner, setIsOpenConfirm }) => {
  const { locale, pathname, asPath, replace, reload } = useRouter();
  const { setModalView, openModal, closeModal } = useUI()
  const [openCountrySelector, setOpenCountrySelector] = useState(false);
  const [openChangeCountry, setOpenChangeCountry] = useState(false);
  const [openPermission, setOPenPermission] = useState(false);
  const [openConfirmLocation, setOpenConfirmLocation] = useState<IAreaDetailInput | undefined>(undefined);
  const Cookies = getCookie();
  const { allBrandsViewed, rejectPermission, confirmPermission, openMainMenu } = useEvents()
  const { country, user, setUserLoggedIn, setUserCountry, setUserArea, setUserFreeShipping } = useAppContext();
  useEffect(() => {
    if (user.isLoggedIn) {
      userApi.profile().fetch().then(res => {
        setUserLoggedIn({ isLoggedIn: true, data: res.data })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isLoggedIn])


  useEffect(() => {
    if (!Cookies.get(COOKIE_COUNTRY_KEY) || !Cookies.get(COOKIE_AREA_KEY)) {
      // setOpenCountrySelector(true);
      setOPenPermission(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cookies.get(COOKIE_COUNTRY_KEY), Cookies.get(COOKIE_AREA_KEY)]);


  useEffect(() => {
    if (Cookies.get(COOKIE_COUNTRY_KEY) && Cookies.get(COOKIE_AREA_KEY)) {
      if (locale?.split("-")[1] && locale?.split("-")[1] !== Cookies.get(COOKIE_COUNTRY_KEY)) {
        Cookies.set(COOKIE_IS_OLD_USER, Cookies.get(COOKIE_COUNTRY_KEY))
        Cookies.remove(COOKIE_COUNTRY_KEY)
        Cookies.remove(COOKIE_AREA_KEY)
      } else {
        Cookies.remove(COOKIE_IS_OLD_USER)
      }
      if (locale === "en" || locale === "ar") {
        Cookies.remove(COOKIE_COUNTRY_KEY)
        Cookies.remove(COOKIE_AREA_KEY)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCurrentLocation = () => {
    setModalView("LOADING_VIEW", false);
    openModal();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          confirmPermission()
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const location = { lat, lng }
          // const location = { lat: 29.1959711, lng: 48.0472399 }
          await commonApi.getCurrentLocation(location).fetch().then(res => {
            if (res.data) {
              const c = res.data.country
              const a = res.data.area
              const userLocale =
                navigator.languages && navigator.languages.length
                  ? navigator.languages[0]
                  : navigator.language
              const selectedLang = userLocale === "ar" ? `ar-${res.data.country.code2}` : `en-${res.data.country.code2}`
              Cookies.set(NEXT_LOCALE_KEY, selectedLang, {}, 365 * 2, false, false);
              Cookies.set(COOKIE_LOCALE_KEY, selectedLang, {}, 365 * 2, false, false);
              Cookies.set(COOKIE_COUNTRY_KEY, res.data.country.code2, {}, 365 * 2, false, false);
              Cookies.set(COOKIE_AREA_KEY, res.data.area.id, {}, 365 * 2, false, false);
              setUserCountry(res.data.country);
              setUserFreeShipping(res.data?.country?.freeshipping || null)
              setUserArea(res.data.area)
              replace(asPath, asPath, {
                locale: selectedLang,
              }).then(() => {
                openMainMenu(c.id, c.slug, a.id, a.slug, selectedLang.includes("ar-") ? "ar" : "en")
                setIsOpenConfirm(true)
                setOpenConfirmLocation(a)
                // reload();
              });
            } else if (res.meta.msg) {
              toast.error(res.meta.msg, getNotifyConfig());
              setOpenCountrySelector(true)
            }

          }).catch(err => {
            console.log(err)
            setOpenCountrySelector(true)
          }).finally(() => {
            closeModal();
          })
        },
        function (error) {
          toast.error("Error getting location", getNotifyConfig());
          closeModal();
          setOpenCountrySelector(true)
          rejectPermission()
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.", getNotifyConfig());
      closeModal();
      setOpenCountrySelector(true)
      rejectPermission()
    }
  }

  const isDynamicMenu = (menu: IMenu | IStaticMenu): menu is IMenu => {
    return (menu as IMenu)[0]?.action !== undefined;
  };


  const onRejectLocation = () => {
    setOPenPermission(false)
    setOpenCountrySelector(true)
    rejectPermission()
  }

  const onConfirmLocation = () => {
    setOPenPermission(false)
    getCurrentLocation()
  }

  const onRejectConfirmation = () => {
    setOpenConfirmLocation(undefined)
    setOpenCountrySelector(true)
  }


  return (
    <header className={`sticky_position sm:border-b xs:border-0 border-0 ${isBanner ? "!top-14" : "!top-0"}`}>
      <div className=" max-w-[1240px] mx-auto">
        {openCountrySelector && (
          <Country
            onClose={setOpenCountrySelector}
            isOpen={openCountrySelector}
            hasCancel={false}
          />
        )}
        {openPermission && <LocationPermission
          isCancelable={false}
          isOpen={openPermission}
          onReject={onRejectLocation}
          onConfirm={onConfirmLocation} />
        }
        {openConfirmLocation && <ConfirmLocation
          isCancelable={true}
          isOpen={openConfirmLocation ? true : false}
          onClose={reload}
          onReject={onRejectConfirmation}
          onConfirm={reload}
          area={openConfirmLocation}
        />
        }
        {openChangeCountry && (
          <ChangeCountry
            onClose={setOpenChangeCountry}
            isOpen={openChangeCountry}
            hasCancel={true}
          />
        )}

        <div className=" sm:hidden xs:block block">
          <MobileHeader openCountryModal={() => setOpenChangeCountry(true)} />
        </div>

        <div
          className="h-[70px] px-3 sm:block xs:hidden hidden"
          id="dabdoob-header-layer1"
        >
          <div className="m-auto w-full flex items-center h-full">
            <div className="flex-1">
              <div className="header-dropdowns font2 flex  items-center ">
                <div className=" h-[50px] ">
                  <SearchBox />
                </div>
                {country && (
                  <div
                    className="h-[50px] w-[80px] mx-3 border rounded-xl flex items-center justify-center cursor-pointer"
                    onClick={() => setOpenChangeCountry(true)}
                  >
                    <div className="w-7 h-7 rounded-full overflow-hidden relative border-2  mx-2">
                      <Image
                        src={country?.image.url || ""}
                        alt={country?.name || ""}
                        layout="fill"
                      />
                    </div>
                    <PathIcon stroke="#333" className=" rotate-90 mx-2" />
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="max-w-[120px]">
                <Link href="/" passHref prefetch={false}>
                  <a>
                    <Image
                      src="/svgs/logo.svg"
                      alt="dabdoob logo"
                      width="202"
                      height="80" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="flex-1 flex items-center justify-end">
              <div className="w-[100px] h-[50px]">
                <ProfileDropdown />
              </div>

              <div className="w-[50px] h-[50px]">
                <ShoppingCart className=" w-full h-full" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-[#222] sm:h-[60px] items-center xs:h-0 h-0 sm:px-3 xs:px-0 px-0 "
          id="dabdoob-header"
        >
          <div className="flex w-full h-full">
            <div className="flex-1  sm:block xs:hidden hidden">
              <nav className="main-nav">
                {isDynamicMenu(menu) ? (
                  <ul className="menu">
                    {menu.map((submenu) => (
                      <li
                        key={`subMenu-${submenu.slug}`}
                        className={`${pathname === linkGenerator(submenu.action) || (pathname === "/" && linkGenerator(submenu.action) === "") || (pathname === "/product" && linkGenerator(submenu.action).includes(asPath.split("?")?.[1])) ? "active" : ""} ${locale?.includes("ar-") ? " float-right" : " float-left"
                          } `}
                      >
                        <Link
                          href={linkGenerator(submenu.action) || "/"}
                          passHref
                          prefetch={false}
                        >
                          <a
                            onClick={() => {
                              if (submenu.action.includes("openBrands")) {
                                allBrandsViewed({
                                  from_page: "menu",
                                  from_entity: "",
                                  from_entity_slug: "",
                                  from_page_section: "",
                                  from_page_section_entity: "",
                                  from_page_section_slug: "",
                                })
                              }
                            }}
                            target={submenu.action.includes("openURL") ? "_blank" : undefined}
                            rel={submenu.action.includes("openURL") ? "noopener noreferrer" : ""}>
                            {locale?.includes("ar-")
                              ? submenu.name_ar
                              : submenu.name_en}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul className="menu">
                    {menu.map((submenu) => (
                      <li
                        key={`subMenu-${submenu.title_en}`}
                        className={`${pathname === submenu.href || (pathname === "/" && submenu.href === "") || (pathname === "/product" && submenu.href.includes(asPath.split("?")?.[1])) ? "active" : ""} ${locale?.includes("ar") ? " float-right" : " float-left"
                          } `}
                      >
                        <Link
                          href={submenu.href || "/"}
                          passHref
                          prefetch={false}
                        >
                          <a
                            target={submenu.external ? "_blank" : undefined}
                            rel={submenu.external ? "noopener noreferrer" : ""}>
                            {locale?.includes("ar")
                              ? submenu.title_ar
                              : submenu.title_en}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
