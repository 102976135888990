import { getCookie } from "@utils/Cookies";
import { COOKIE_AREA_KEY, COOKIE_COUNTRY_KEY } from "@utils/constants";
import { commonApi } from "gate";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export const hasArea = () => {
  const Cookies = getCookie();
  return Cookies.get(COOKIE_AREA_KEY) ? true : false;
};

export const hasCountry = () => {
  const Cookies = getCookie();
  return Cookies.get(COOKIE_COUNTRY_KEY) ? true : false;
};

export const setCountryAndArea = async (locale?: string) => {
  const url_country = locale?.split("-")[1];
  const isSame = url_country === publicRuntimeConfig.DEFAULT_COUNTRY_CODE;
  if (!hasCountry()) {
    const Cookies = getCookie();
    Cookies.set(
      "DAB_COUNTRY",
      isSame ? publicRuntimeConfig.DEFAULT_COUNTRY_CODE : url_country ?? "KW",
      {},
      365
    );
  }
  if (!hasArea()) {
    const Cookies = getCookie();
    if (isSame) {
      try {
        const areaList = await commonApi
          .getAreaList(publicRuntimeConfig.DEFAULT_COUNTRY_ID)
          .fetch();
        const area = areaList.data.items.find((area) => area.items.length !== 0);
        Cookies.set("DAB_AREA", area?.items[0].id ?? "8", {}, 365, false, false);
      } catch (error) {
        console.log(error);
        Cookies.set("DAB_AREA", "8", {}, 365, false, false);
      }
    } else {
      try {
        const countries = await commonApi
          .getCountryList()
          .fetch()
          .then((res) => res.data?.items || []);
        const country = countries.find((country) => country.code2 === url_country);
        const areaList = await commonApi
          .getAreaList(country?.id ?? publicRuntimeConfig.DEFAULT_COUNTRY_ID)
          .fetch()
          .then((res) => res.data.items || []);
        const area = areaList.find((area) => area.items.length !== 0);
        Cookies.set("DAB_AREA", area?.items[0].id ?? "8", {}, 365, false, false);
      } catch (error) {
        console.log(error);
        Cookies.set("DAB_AREA", "8", {}, 365, false, false);
      }
    }
  }
};
